import React from 'react';
import ContactForm from '@/components/ContactForm/ContactForm';
import './ContactMobile.scss';

const ContactMobile = ({ locale, closeContact }) => (
	<div className="contact-mobile">
		{/* <div onClick={closeContact}>CLOSE</div> */}
		<ContactForm locale={locale} />
	</div>
);

export default ContactMobile;
