import React, { useState } from 'react';
import './MenuMobile.scss';
import Mail from '@/images/mail.inline.svg';
import ContactMobile from '@/components/ContactMobile/ContactMobile';

const MenuMobile = ({ locale, linkList }) => {
	const [isContactOpen, setIsContactOpen] = useState(false);
	return (
		<>
			{isContactOpen && <ContactMobile locale={locale} closeContact={() => setIsContactOpen(false)} />}
			<div className="menu-mobile">
				<ul className="menu-mobile-links">
					{linkList}
					<li className="we-hire">
						<a
							href={`https://www.welcometothejungle.com/${locale}/companies/ombrea`}
							rel="noopener noreferrer"
							target="_blank">
							{locale === 'fr' ? 'On recrute !' : 'We hire!'}
						</a>
					</li>
				</ul>

				<button onClick={() => setIsContactOpen(true)} className="btn-contact">
					<Mail width={40} height={40} fill="#1D7B33" />
				</button>
			</div>
		</>
	);
};

export default MenuMobile;
