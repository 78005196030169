import React from 'react';

const NEWSLETTER_WIDGET_IDS = {
	en: 'kmr',
	fr: 'kmh'
};

const Newsletter = ({ locale }) => (
	<>
		<iframe
			className="mj-w-res-iframe"
			frameBorder="0"
			scrolling="no"
			marginHeight="0"
			marginWidth="0"
			src={`https://app.mailjet.com/widget/iframe/3Nhw/${NEWSLETTER_WIDGET_IDS[locale]}`}
			width="100%"></iframe>
		<script type="text/javascript" src="https://app.mailjet.com/statics/js/iframeResizer.min.js"></script>
	</>
);

export default Newsletter;
