import React from 'react';
import './Layout.scss';
import './reset.scss';
import Header from '@/components/Header/Header';
import Head from './Head';
import Footer from '@/components/Footer/Footer';
import ContactForm from '@/components/ContactForm/ContactForm';
import ScrollTop from '@/components/ScrollTop/ScrollTop';

const Layout = ({ children, withCover, locale, url, activeLink }) => (
	<div className="app">
		<Head />
		<Header withCover={withCover} url={url} locale={locale} activeLink={activeLink} />
		<main>{children}</main>
		<ContactForm locale={locale} />
		<Footer locale={locale} />
		<ScrollTop />
	</div>
);

export default Layout;
