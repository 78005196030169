import { useState, useEffect } from 'react';

export default (elRef, offSet, delay = 250) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const innerHeight = typeof window === 'undefined' ? 800 : window.innerHeight;
		const _offset = offSet ? offSet : innerHeight - 400;
		let id;
		function check() {
			if (!elRef.current || isVisible) return;
			const rect = elRef.current.getBoundingClientRect();
			const topVisibility = rect.top - _offset <= 0;
			return topVisibility && setIsVisible(topVisibility);
		}
		check();
		if (isVisible) return () => clearInterval(id);
		id = setInterval(check, delay);
		return () => clearInterval(id);
	}, [isVisible]);

	return [isVisible];
};
