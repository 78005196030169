import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => (
	<Helmet>
		<link
			rel="stylesheet"
			type="text/css"
			charset="UTF-8"
			href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
		/>
		<link
			rel="stylesheet"
			type="text/css"
			href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
		/>
		<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
		<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
	</Helmet>
);

export default Head;
