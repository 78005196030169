import React, { useState } from 'react';
import './Footer.scss';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import Logo from '../../images/ombrea.inline.svg';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import Newsletter from '@/components/Newsletter/Newsletter';
import moment from 'moment';

const wording = {
	en: {
		followUs: 'Follow our news!',
		findUs: 'Find us:',
		legalMention: 'Legal Mentions',
		legalContent: (
			<div>
				<p>
					Numéro d’inscription au registre du commerce et des sociétés (RCS) ; 823 339 627 <br />
					Numéro individuel d’identification fiscale (TVA): FR 29 823 339 627
				</p>
				<p>
					Forme juridique de la société: SAS <br />
					Dénomination sociale ou raison sociale: Ombrea <br />
					Adresse du siège social: Domaine du Petit Arbois, Avenue Louis Philibert, 13100 Aix en Provence <br />
					Numéro de téléphone et adresse de courrier électronique: contact@ombrea.fr 07 57 08 14 14 34 <br />
					Montant du capital social: 69 335 €
				</p>
				<p>Publication director: Julie Davico-Pahin.</p>
				<p>
					Web host : Zeit, Inc., CORPORATION, 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Ireland Code of
					business: C3840731 Website:{' '}
					<a href="https://zeit.co" rel="noreferrer" target="_blank">
						https://zeit.co
					</a>{' '}
					Contact: <a mailto="privacy@zeit.co">privacy@zeit.co</a>
				</p>
			</div>
		),
		gdpr: {
			text: 'By continuing to browse, you agree to the use of cookies to measure our audience.',
			decline: 'Decline',
			more: 'Read more',
			ok: 'Ok'
		}
	},
	fr: {
		followUs: 'Suivez notre actualité !',
		findUs: 'Nous trouver :',
		legalMention: 'Mentions légales',
		legalContent: (
			<div>
				<p>
					Numéro d’inscription au registre du commerce et des sociétés (RCS) ; 823 339 627 <br />
					Numéro individuel d’identification fiscale (TVA): FR 29 823 339 627
				</p>
				<p>
					Forme juridique de la société: SAS <br />
					Dénomination sociale ou raison sociale: Ombrea <br />
					Adresse du siège social: Domaine du Petit Arbois, Avenue Louis Philibert, 13100 Aix en Provence <br />
					Numéro de téléphone et adresse de courrier électronique: contact@ombrea.fr 07 57 08 14 14 34 <br />
					Montant du capital social: 69 335 €
				</p>
				<p>Directrice de publication : Julie Davico-Pahin.</p>
				<p>
					Hébergeur : Zeit, Inc., CORPORATION, 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Ireland Code of
					business: C3840731 Site web:{' '}
					<a href="https://zeit.co" rel="noreferrer" target="_blank">
						https://zeit.co
					</a>{' '}
					Contact: <a mailto="privacy@zeit.co">privacy@zeit.co</a>
				</p>
			</div>
		),
		gdpr: {
			text: "En poursuivant votre navigation, vous acceptez l'utilisation de cookies pour mesurer notre audience.",
			decline: 'Decline',
			more: 'En savoir plus',
			ok: 'Ok'
		}
	}
};

const Footer = ({ locale }) => {
	let [showLegal, setShowLegal] = useState(false);
	let [showGDPR, setShowGDPR2] = useState(true);

	function saveCookiesGDPR() {
		const gdpr = {
			expire: moment()
				.add(6, 'months')
				.format(),
			accept: true,
			hiddeBadge: true
		};
		localStorage.setItem('gdpr', JSON.stringify(gdpr));
	}

	function checkCookiesGDPR() {
		const gdpr = typeof window !== 'undefined' && window.localStorage.getItem('gdpr');
		if (gdpr) {
			const gdprFormatted = JSON.parse(gdpr);
			const now = moment();
			const expire = moment(gdprFormatted.expire);
			const diff = expire.diff(now, 'days');
			return gdprFormatted.hiddeBadge && diff > 1 ? false : true;
		}
		return true;
	}

	const data = useStaticQuery(
		graphql`
			query FooterQuery {
				pages: allStrapiPages(sort: { fields: orderInHeader }) {
					nodes {
						url {
							url
							label
							locales {
								locale
								localeName
							}
						}
					}
				}
			}
		`
	);
	return (
		<footer className="footer">
			<ContentWrapper className="footer-content">
				<div className="columns">
					<div className="col">
						<Logo width={60} height={60} />
						<a className="telephone" href="tel:+33 7 57 08 14 34">
							+33 7 57 08 14 34
						</a>
						<address>Domaine du Petit Arbois, Avenue Louis Philibert, 13100 Aix-en-Provence</address>
					</div>
					<div className="col">
						<ul className="sitemap">
							{data.pages.nodes.map(({ url }, i) => {
								const pageUrl = filterLocaleItems(locale)(url);
								return (
									<li key={i}>
										<Link to={pageUrl.url}>{pageUrl.label}</Link>
									</li>
								);
							})}
							<li onClick={() => setShowLegal(!showLegal)}>
								<a href="#legal-mentions">{wording[locale].legalMention}</a>
							</li>
						</ul>
					</div>
					<div className="col">
						<a
							href="https://www.google.com/maps/place/Ombrea/@43.4911633,5.3305133,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9edbe5c10eead:0xfd19e73c82b1f5ed!8m2!3d43.4911633!4d5.3305133"
							rel="noopener noreferrer"
							target="_blank"
							className="find-us">
							{wording[locale].findUs}
							<img src="/map-ombrea.png" alt="find-us" />
						</a>
					</div>
					<div className="col">
						<strong className="newsletter-text">{wording[locale].followUs}</strong>
						<Newsletter locale={locale} />
					</div>
				</div>
				<div className="legal-mentions" id="legal-mentions">
					{showLegal && <div className="legal-mentions__content">{wording[locale].legalContent}</div>}
				</div>
			</ContentWrapper>

			{showGDPR && checkCookiesGDPR() ? (
				<div className="modal-gdpr">
					<div>{wording[locale].gdpr.text}</div>
					<div className="list-buttons">
						<a href="https://google.fr" rel="noreferrer" className="button">
							{wording[locale].gdpr.decline}
						</a>
						<a
							href={`https://www.cnil.fr/${locale}/definition/cookie`}
							rel="noreferrer"
							target="_blank"
							className="button">
							{wording[locale].gdpr.more}
						</a>
						<button
							className="button"
							onClick={() => {
								setShowGDPR2(!showGDPR);
								saveCookiesGDPR();
							}}>
							{wording[locale].gdpr.ok}
						</button>
					</div>
				</div>
			) : (
				''
			)}
		</footer>
	);
};

export default Footer;
