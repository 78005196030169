import React from 'react';
import './Button.scss';

const Button = ({ label, onClick, isSubmit }) => (
	<button type={isSubmit ? 'submit' : 'button'} className="button">
		{label}
	</button>
);

export default Button;
