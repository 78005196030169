import React, { useRef } from 'react';
import { Animated } from 'react-animated-css';
import cn from 'classnames';
import './Section.scss';
import useVisibiltySensor from '@/hooks/useVisibiltySensor';

const getDelay = (isHero, isNews, isDiscover) => {
	const innerHeight = typeof window === 'undefined' ? 800 : window.innerHeight;

	if (isDiscover) return innerHeight - 400;
	if (isHero || isNews) return 0;
	return innerHeight - 400;
};

const Section = ({ strates, className, children, id = '', isHero = false, isNews = false, isDiscover = false }) => {
	let stratesRef = useRef(null);
	const [isVisible] = useVisibiltySensor(stratesRef, getDelay(isHero, isNews, isDiscover));

	return (
		<section className={`section ${className}`} id={id}>
			<div className="background-strates" ref={stratesRef}>
				{strates.map(({ width, color, withIllu }, i) => (
					<Animated
						animationIn={i <= 1 ? 'slideInLeft' : 'slideInRight'}
						isVisible={isVisible}
						key={i}
						className={cn('strate', {
							'has-illu': withIllu
						})}
						style={{ width, backgroundColor: color }}
					/>
				))}
			</div>
			{children}
		</section>
	);
};
export default Section;
