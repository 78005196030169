import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './ContactForm.scss';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import Title from '@/components/Title/Title';
import Button from '@/components/Button/Button';
import { useStaticQuery, graphql } from 'gatsby';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { COLORS } from '../../constants';
import Section from '../Section/Section';

const { YELLOW, WHITE } = COLORS;
const STRATES = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: YELLOW,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	}
];

const ContactForm = ({ locale }) => {
	const data = useStaticQuery(
		graphql`
			query ContactBlockQuery {
				contactWording: strapiContacts {
					ContactGroup {
						LabelEmail
						LabelMessage
						LabelName
						LabelPhone
						TextButton
						title
						locales {
							locale
							localeName
						}
					}
				}
			}
		`
	);
	const { LabelName, LabelEmail, LabelMessage, LabelPhone, TextButton, title } = filterLocaleItems(locale)(
		data.contactWording.ContactGroup
	);

	const [serverState, setServerState] = useState({
		submitting: false,
		type: null,
		message: null
	});

	const recaptchaRef = React.createRef();

	const handleServerResponse = (submitting, type, msg, form) => {
		setServerState({
			submitting: submitting,
			type: type,
			message: msg
		});
		if (submitting && form) {
			form.reset();
		}
	};

	const onChangeReCaptcha = event => {};

	const handleOnSubmit = e => {
		e.preventDefault();
		const form = e.target;
		const recaptchaValue = recaptchaRef.current.getValue();

		if (!recaptchaValue) {
			handleServerResponse(true, 'warning', 'Indiquez que vous n’êtes pas un robot', null);
			return;
		}

		axios({
			method: 'post',
			url: 'https://getform.io/f/71304c3f-e9eb-46ae-894f-156c03612a15',
			data: new FormData(form)
		})
			.then(r => {
				handleServerResponse(true, 'success', 'Message envoyé correctement', form);
			})
			.catch(r => {
				handleServerResponse(true, 'danger', 'Une erreur s’est produite, réessayez ultérieurement.', null);
			});
	};

	return (
		<Section className="contact-form" id="contact" strates={STRATES}>
			<ContentWrapper>
				<Title level={3}>{title}</Title>
				<form onSubmit={handleOnSubmit}>
					{serverState.submitting ? (
						<div className={`alert alert--${serverState.type}`}>{serverState.message}</div>
					) : (
						''
					)}
					<div className="col-wrapper">
						<div className="col">
							<input required name="name" type="text" placeholder={LabelName} />
							<input required name="email" type="email" placeholder={LabelEmail} />
							<input name="tel" type="tel" placeholder={LabelPhone} />
						</div>
						<div className="col">
							<textarea minLength={10} required name="message" placeholder={LabelMessage}></textarea>
						</div>
					</div>
					<div className="col-wrapper">
						<ReCAPTCHA
							ref={recaptchaRef}
							hl={locale}
							sitekey="6LetN8ccAAAAAEhEc78-VbEGIl3X959AWT7JUxDX"
							onChange={onChangeReCaptcha}
						/>
					</div>
					<Button isSubmit label={TextButton} />
				</form>
			</ContentWrapper>
		</Section>
	);
};

export default ContactForm;
