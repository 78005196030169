import React from 'react';
import cn from 'classnames';
import './Title.scss';

const Title = ({ level, children, className }) => {
	const TitleTag = level > 6 ? 'h6' : `h${level}`;

	return <TitleTag className={cn(`title title-${level}`, className)}>{children}</TitleTag>;
};

export default Title;
