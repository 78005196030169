export const SLIDER_DEFAULT_SETTING = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false
};

export const COLORS = {
	GREEN: '#65B22E',
	YELLOW: '#DBD145',
	LIGHTGREY: '#F9F9F9',
	WHITE: '#FFF',
	GREY: '#F9F9F9'
};

export const BLOCKS = {};

export const BLOCK_NAMES = {
	OMBREA_SYSTEM_SLIDER: 'OMBREA_SYSTEM_SLIDER',
	SUPPORTS: 'SUPPORTS',
	DISTINCTIONS: 'DISTINCTIONS',
	NEWS: 'NEWS',
	DISCOVER_HOMEBLOCK: 'DISCOVER_HOMEBLOCK',
	HOME_EDITORIAL: 'HOME_EDITORIAL',
	FEATURES_POINTS: 'FEATURES_POINTS',
	AVANTAGE_SLIDER: 'AVANTAGE_SLIDER',
	TEAM: 'TEAM',
	VALUES: 'VALUES',
	SCIENCES_PARTNERSHIP: 'SCIENCES_PARTNERSHIP',
	OUR_RESULTS: 'OUR_RESULTS',
	SITE_TYPE: 'SITE_TYPE',
	SUBSIDIARY: 'SUBSIDIARY',
	TESTIMONY: 'TESTIMONY',
	NEWS_POST_LIST: 'NEWS_POST_LIST',
	FAQ_BLOCK: 'FAQ_BLOCK',
	AGRONOMIC_EXPERTISE: 'AGRONOMIC_EXPERTISE'
};
